.about-us {
}

.about-us-container {
  width: 900px;
}

.about-us-banner {
  background-position: center;
  background-size: cover;
  background-attachment: scroll;
  color: white;
  height: 300px;
  /* opacity: 95%; */
  width: 100%;
}

.about-us-banner-heading-background {
  background-image: url(../../../Images/aboutusbanner.png);
}

.about-us-banner-awards-background {
  background-image: url(../../../Images/awards-banner-image.png);
}

.about-us-header {
  position: absolute;
  font-size: 63px;
  font-weight: bold;
  text-shadow: rgba(255, 255, 255, 0.6) 1px 1px 1px,
    rgba(0, 0, 0, 0.6) -1px -1px 1px;
  width: 100%;
}

.about-us-credit-link {
  align-self: flex-start;
  color: white;
  margin-top: 1px;
  margin-left: 3%;
}

.about-us-banner-overlay {
  align-items: center;
  display: flex;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
}

.about-us-text-container {
  justify-content: center;
  display: flex;
  background-color: rgba(235, 235, 235);
}

.about-us-text {
  padding: 2em 2em 0 2em;
  width: 950px;
  background-color: white;
}

@media only screen and (max-width: 1000px) {
  .about-us-banner {
    height: calc((100vw / 1000) * 300);
  }
}

@media only screen and (max-width: 950px) {
  .about-us-text {
    width: 100%;
  }
}

.wrapper {
  height: 100vh;
  perspective: 1px;
  /* transform-style: preserve-3d; */
  overflow-x: hidden;
  overflow-y: auto;
}
.snow-wrapper {
  background-image: url(../../../Images/aboutusbanner.png);
  height: 200vh;
  /* background: radial-gradient(
    farthest-corner at 30vw 20vh,
    #7397a1 1%,
    #3f2c41 100%
  ); */
  /* transform-style: preserve-3d; */
  z-index: -1;
}
.content-wrapper {
  opacity: 0.9;
  z-index: 1;
  background: #aaa;
  padding: 5vh 5vw;
  top: 50vh;
  position: absolute;
  transform: translateZ(-1px) scale(2);
}
