.spotify-section-container {
  display: flex;
  justify-content: center;
  margin: 100px 0 10px;
}

.spotify-section-container-contents {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 900px;
}

.social-media-spotify-follow-container {
  width: 33%;
}

.spotify-section-spotify-container {
  display: flex;
  justify-content: center;
  width: 33%;
}

.spotify-section-album-image-container {
  display: flex;
  justify-content: center;
}

.social-media-spotify-text-container {
  text-align: center;
  font-size: xxx-large;
  font-family: sans-serif;
  font-weight: 300;
  line-height: normal;
  letter-spacing: normal;
}

.social-media-spotify-text-container.heading-center {
  height: auto;
  width: auto;
  display: auto;
}

.social-media-spotify-text-container.heading-top {
  display: none;
}

@media only screen and (max-width: 900px) {
  .spotify-section-container {
    margin: 50px 0 10px;
  }

  .spotify-section-container-contents {
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .social-media-spotify-follow-container {
    width: 100%;
    margin: 20px 0;
  }

  .spotify-section-spotify-container {
    width: 100%;
    padding: 10px 10px 0;
  }

  .social-media-spotify-text-container.heading-top {
    height: auto;
    width: auto;
    display: initial;
  }

  .social-media-spotify-text-container.heading-center {
    display: none;
  }
}
