.social-media {
  display: flex;
  justify-content: center;
  margin: 0 0 70px;
}

.social-media-container {
}

.social-media-content {
  display: flex;
  flex-direction: row;
  width: 950px;
  text-align: center;
  font-size: xx-large;
  font-family: sans-serif;
  font-weight: 300;
  line-height: normal;
  letter-spacing: normal;
}

.social-media-text-container {
  display: inline-block;
  width: 300px;
  padding: 50px 0 20px;
}

.social-media-facebook-container {
  width: 45%;
}

.social-media-instagram-container {
  width: 55%;
}

.frame_container_item {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 950px) {
  .social-media-content {
    flex-direction: column;
    width: 100%;
  }

  .social-media-facebook-container {
    width: 100%;
  }

  .social-media-instagram-container {
    width: 100%;
  }
}
