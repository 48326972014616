.Carousel {
  background-color: rgba(106, 24, 36, 1);
  height: 150px;
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  align-items: center;
  display: flex;
}

.Carousel-Item {
  width: 100%;
  /* height: 100%; */
  margin: 0;
  padding: 0;
  text-align: center;
  /* line-height: 100%; */
  position: absolute;
  color: white;
}

.Carousel-Item-Text {
  /* width: 100%; */
  /* font-size: xx-large; */
  /* line-height: 150px; */
}

.carousel-inner {
  /* align-self: center; */
  /* height: 100%; */
}

/* ------------------------------------------------ */

/* .carousel-item {
  height: 100vh;
  background-size: cover;
  background-position: center center;
  background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15e27d22494%20text%20%7B%20fill%3A%23333%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15e27d22494%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23555%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22277%22%20y%3D%22218.45%22%3EThird%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E);
} */

/* .carousel-item h1 {
  margin: 0;
  color: white;
} */

/* ------------------------------------------------ */

/* body {
  overflow-x: hidden;
  height: 100%;
} */

/* .carousel-item:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
} */

/* .carousel-item {
  height: 100vh;
} */

/* .carousel-caption {
  transform: translateY(-50%);
  bottom: 0;
  top: 50%;
} */

/* .carousel-image-1 {
  background: url("../images/miami1.jpeg");
  background-size: cover;
} */

/* .carousel-image-2 {
  background: url("../images/miami2.jpeg");
  background-size: cover;
} */

/* .carousel-image-3 {
  background: url("../images/miami3.jpeg");
  background-size: cover;
} */
