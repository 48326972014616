.members-background {
  background-image: url(../../../Images/bridge-background.png);
  background-size: cover;
  background-position: center;
  justify-content: center;
  display: flex;
}

.members-content {
  width: 980px;
  background-color: white;
}

.members-header {
  margin-top: 20px;
  font: normal normal normal 63px/1.4em raleway, sans-serif;
  font-weight: 300;
}

.members-image-container {
  height: 200px;
  width: 200px;
  overflow: hidden;
}

.members-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: 50% 17%;
}

.members-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  padding: 0 20px 40px 20px;
}

.members-text {
  /* width: 100%; */
  text-align: center;
}

@media only screen and (max-width: 525px) {
  .members-image-container {
    width: calc((100vw / 525) * 200);
    height: calc((100vw / 525) * 200);
  }

  .members-text {
    width: calc((100vw / 525) * 200);
    /* width: 100%; */
  }
}
