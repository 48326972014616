.burger-menu {
  display: none;
  position: fixed;
  z-index: 11;
  border: none;
  background-color: transparent;
}

.Navspace {
  height: 60px;
}

.NavBar {
  background-color: white;
  height: 60px;
  width: 100vw;
  display: flex;
  flex-direction: row;
  /* position: absolute;
  top: 0;
  left: 0; */
  top: 0px;
  position: fixed;
  justify-content: space-evenly;
  z-index: 10;
}

.nav-bar-mobile {
  background-color: white;
  height: 60px;
  width: 100vw;
  display: flex;
  flex-direction: row;
  /* position: absolute;
  top: 0;
  left: 0; */
  top: 0px;
  position: fixed;
  justify-content: space-between;
  z-index: 10;
}

.nav-bar-mobile-item {
  width: 55px;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.navbar-link {
  background-color: white;
  color: grey;
  text-align: center;
  width: calc(100vw / 6);
  border: none;
  height: 100%;
}

.navbar-link:hover {
  background-color: rgba(240, 240, 240);
  color: rgba(106, 24, 36, 1);
}

.navbar-link-dropdown {
  width: calc(100vw / 6);
  height: 250%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.navbar-link-dropdown-item {
  width: 100%;
  height: 50%;
}

.navbar-link-dropdown-inactive {
  height: 100%;
}

.active {
  color: rgba(106, 24, 36, 1);
}

.a {
  color: rgba(106, 24, 36, 1);
}

@media only screen and (max-width: 650px) {
  .burger-menu {
    display: block;
  }

  .NavBar {
    top: 60px;
    flex-direction: column;
    justify-content: start;
    background-color: white;
    height: 100%;
    width: 150px;
    left: calc(100vw - 150px);
  }

  .navbar-link {
    width: 150px;
    height: 60px;
  }

  .navbar-link-dropdown {
    width: inherit;
    height: 240px;
  }

  .navbar-link-dropdown-item {
    height: 60px;
  }

  .navbar-link-dropdown-inactive {
    height: 60px;
  }
}
