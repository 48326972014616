/*.homepage-banner-image {*/
/*    float: left;*/
/*    display: inline-block;*/
/*    width: 100%;*/
/*}*/

.homepage-banner-image {
  background-image: url(../../Images/homepage-banner.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 41vw;
}

.logo {
  position: absolute;
  width: 50%;
  display: flex;
  padding-left: 25%;
  padding-right: 25%;
  padding-top: 21%;
  box-sizing: unset;
}

.homepage-banner {
  height: auto;
  display: grid;
}

@media only screen and (max-width: 600px) {
  .logo {
    position: relative;
    padding-top: 0px;
    background-color: rgba(106, 24, 36, 1);
  }
}
