.contact {
  display: flex;
  justify-content: center;
  background-image: url(../../Images/bridge-background.png);
}

.contact-container {
  width: 900px;
}

.contact-banner {
  background-image: url(../../Images/contact-banner.png);
  background-position: center;
  background-size: cover;
  background-attachment: scroll;
  color: white;
  align-items: center;
  display: flex;
  /* background-color: rgba(0, 0, 0, 0.3); */
  height: 300px;
  /* opacity: 95%; */
  width: 100%;
}

.contact-header {
  z-index: 1;
  font-size: 63px;
  font-weight: bold;
  text-shadow: rgba(255, 255, 255, 0.6) 1px 1px 1px,
    rgba(0, 0, 0, 0.6) -1px -1px 1px;
  width: 100%;
}

.contact-container {
  background-color: rgba(235, 235, 235);
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 950px;
}

.contact-text {
  padding: 2em 2em 0 2em;
  text-align: center;
}

.contact-form {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.contact-form > * {
  margin-top: 10px;
}

.contact-form > input,
textarea {
  box-shadow: 3px 5px 0px 0px rgba(0, 0, 0, 0.3);
  border: none;
  border-radius: 3px;
  padding: 4px 8px;
}

@media only screen and (max-width: 1000px) {
  .contact-banner {
    height: calc((100vw / 1000) * 300);
  }
}

@media only screen and (max-width: 950px) {
  .contact-text {
    width: 100%;
  }
}

