.alumni-background {
  background-image: url(../../../Images/bridge-background.png);
  background-size: cover;
  background-position: center;
  justify-content: center;
  display: flex;
}

.alumni-content {
  overflow: scroll;
  background-color: white;
  width: 90%;
  padding: 10px;
}

.alumni-header {
  margin-top: 20px;
  font: normal normal normal 63px/1.4em raleway, sans-serif;
  font-weight: 300;
}

@media only screen and (max-width: 980px) {
  .alumni-content {
    width: 100%;
  }
}
