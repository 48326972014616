/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
} */

h1 {
  text-align: center;
}

p {
  width: 100%;
  font: normal normal normal 22px/1.4em raleway, sans-serif;
}

p, a {
  font-size: 23px;
}

strong {
  color: rgba(106, 24, 36);
}

button {
  font-size: initial !important;
}
